<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="formType === 'ADD' ? $t('agentBanner.btn.add') : $t('agentBanner.btn.edit')"
    class="px-5 py-3"
  >
    <page-loading :show="loading" />
    <form @submit.prevent="submit">
      <div class="form--group row" :class="{ 'has-error': validation.hasError('appImage') }">
        <label class="col-12 col-lg-3" for="appImage">Banner Image</label>
        <div class="col-12 col-lg-6 photo--section">
          <!--ini preview gambar-->
          <div class="photo--preview-wrapper" v-show="appImage">
            <photo-preview
              length="1"
              :photo="appImage"
              :isFloorPlan="false"
              :noPrimary="true"
              removePhotoDispatch="banner/form/REMOVE_APP_IMAGE"
              v-if="appImage != null"
            />
          </div>
          <vue-dropzone
            v-show="false"
            ref="photoDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-file-added="added"
            @vdropzone-sending="sending"
            @vdropzone-success="success"
            @vdropzone-complete="completed"
          />
          <div class="d-flex align-center justify-center mt-4">
            <v-btn
              type="button"
              color="primary"
              :disabled="appImage != null"
              class="btn btn-primary--light btn-block"
              @click="triggerDropzone"
            >
              {{ $t('project.upload') }}
            </v-btn>
          </div>
          <span class="val-error">{{ validation.firstError('appImage') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('bannerType') }">
        <label class="col-12 col-lg-3" for="selectedStatus">Tipe Banner</label>
        <div class="col-12 col-lg-6">
          <multiselect
            name="bannerType"
            id="bannerType"
            v-model="bannerType"
            :options="bannerTypes"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('bannerType') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('title') }">
        <label class="col-12 col-lg-3" for="title">Judul</label>
        <div class="col-12 col-lg-6">
          <v-text-field id="title" v-model="title" type="text" name="title" outlined hide-details />
          <span class="val-error">{{ validation.firstError('title') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('description') }">
        <label class="col-12 col-lg-3" for="description">Deskripsi</label>
        <div class="col-12 col-lg-6">
          <v-textarea
            id="description"
            v-model="description"
            type="text"
            name="description"
            outlined
            hide-details
            rows="8"
          />
          <span class="val-error">{{ validation.firstError('description') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('buttonName') }">
        <label class="col-12 col-lg-3" for="buttonName">Button Name</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="buttonName"
            v-model="buttonName"
            type="text"
            name="buttonName"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('buttonName') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('externalUrl') }">
        <label class="col-12 col-lg-3" for="externalUrl">External URL</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="externalUrl"
            v-model="externalUrl"
            type="text"
            name="externalUrl"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('externalUrl') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('flutterUrl') }">
        <label class="col-12 col-lg-3" for="flutterUrl">Flutter URL</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="flutterUrl"
            v-model="flutterUrl"
            type="text"
            name="flutterUrl"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('flutterUrl') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('sequenceNumber') }">
        <label class="col-12 col-lg-3" for="sequenceNumber">Sequence Number</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="sequenceNumber"
            v-model="sequenceNumber"
            type="text"
            name="sequenceNumber"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('sequenceNumber') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('isTakeover') }">
        <label class="col-12 col-lg-3" for="isTakeover">{{ $t('agentBanner.isTakeover') }}</label>
        <div class="col-12 col-lg-6">
          <multiselect
            name="isTakeover"
            id="isTakeover"
            v-model="isTakeover"
            :options="isTakeoverList"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('isTakeover') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('selectedStatus') }">
        <label class="col-12 col-lg-3" for="selectedStatus">Availability Status</label>
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedStatus"
            id="selectedStatus"
            v-model="selectedStatus"
            :options="availabilityStatuses"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedStatus') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('visitAmount') }"
        v-if="formType === 'EDIT'"
      >
        <label class="col-12 col-lg-3" for="visitAmount">Jumlah Kunjungan</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="visitAmount"
            v-model="visitAmount"
            type="text"
            name="visitAmount"
            outlined
            hide-details
            readonly
          />
          <span class="val-error">{{ validation.firstError('visitAmount') }}</span>
        </div>
      </div>
      <div class="d-flex align-center justify-end">
        <v-btn color="primary" class="bottom--button" type="submit">
          {{ $t('agentBanner.btn.save') }}
        </v-btn>
      </div>
    </form>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
const PhotoPreview = () => import('@/components/project-management/project/form/photo-preview.vue');
import vue2Dropzone from 'nuxt-dropzone';

export default {
  name: 'unit-type-form',
  mixins: [HelperMixin],
  components: { PageLoading, Multiselect, vueDropzone: vue2Dropzone, PhotoPreview },
  data() {
    return {
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/file_content/upload_photo`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      formType: (state) => state.banner.form.formType,
      availabilityStatuses: (state) => state.global.availabilityStatuses,
      bannerTypes: (state) => state.global.bannerTypes,
      isTakeoverList: (state) => state.global.isTakeoverList,
    }),
    loading: {
      get() {
        return this.$store.state.banner.form.loading;
      },
      set(value) {
        this.$store.commit('banner/form/SET_LOADING', value);
      },
    },
    appImage: {
      get() {
        return this.$store.state.banner.form.appImage;
      },
      set(value) {
        this.$store.commit('banner/form/SET_APP_IMAGE', value);
      },
    },
    selectedStatus: {
      get() {
        return this.$store.state.banner.form.selectedStatus;
      },
      set(value) {
        this.$store.commit('banner/form/SET_SELECTED_STATUS', value);
      },
    },
    bannerType: {
      get() {
        return this.$store.state.banner.form.bannerType;
      },
      set(value) {
        this.$store.commit('banner/form/SET_BANNER_TYPE', value);
      },
    },
    buttonName: {
      get() {
        return this.$store.state.banner.form.buttonName;
      },
      set(value) {
        this.$store.commit('banner/form/SET_BUTTON_NAME', value);
      },
    },
    externalUrl: {
      get() {
        return this.$store.state.banner.form.externalUrl;
      },
      set(value) {
        this.$store.commit('banner/form/SET_EXTERNAL_URL', value);
      },
    },
    flutterUrl: {
      get() {
        return this.$store.state.banner.form.flutterUrl;
      },
      set(value) {
        this.$store.commit('banner/form/SET_FLUTTER_URL', value);
      },
    },
    sequenceNumber: {
      get() {
        return this.$store.state.banner.form.sequenceNumber;
      },
      set(value) {
        this.$store.commit('banner/form/SET_SEQUENCE_NUMBER', value);
      },
    },
    translations: {
      get() {
        return this.$store.state.banner.form.translations;
      },
      set(value) {
        this.$store.commit('banner/form/SET_TRANSLATIONS', value);
      },
    },
    title: {
      get() {
        return this.$store.state.banner.form.title;
      },
      set(value) {
        this.$store.commit('banner/form/SET_TITLE', value);
      },
    },
    description: {
      get() {
        return this.$store.state.banner.form.description;
      },
      set(value) {
        this.$store.commit('banner/form/SET_DESCRIPTION', value);
      },
    },
    isTakeover: {
      get() {
        return this.$store.state.banner.form.isTakeover;
      },
      set(value) {
        this.$store.commit('banner/form/SET_IS_TAKEOVER', value);
      },
    },
    visitAmount: {
      get() {
        return this.$store.state.banner.form.visitAmount;
      },
      set(value) {
        this.$store.commit('banner/form/SET_VISIT_AMOUNT', value);
      },
    },
  },

  validators: {
    selectedStatus(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agentBanner.status.required'));
    },
    bannerType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agentBanner.type.required'));
    },
    title(value) {
      return Validator.value(value).required(this.$i18n.t('errors.agentBanner.title.required'));
    },
  },
  methods: {
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
    },
    added(file) {
      this.loading = true;
      const dropzone = this.$refs.photoDropzone;
      const maxFile = 1;
      const listPhoto = this.appImage ? 1 : 0;
      const uploading = dropzone.getUploadingFiles().length;
      const queued = dropzone.getQueuedFiles().length;
      const totalPhoto = listPhoto + uploading + queued;
      this.addedPhoto = true;
      if (totalPhoto >= maxFile) {
        dropzone.removeFile(file);
        // eslint-disable-next-line no-undef
        this.loading = false;
        this.$swal(
          this.$t('errors.general.photo.maxTitle'),
          this.$i18n.t('errors.general.photo.maxMessage', {
            max: maxFile,
          }),
          'error',
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      this.loading = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      let img = file.previewElement.querySelector('img');
      this.$store.commit('banner/form/ADD_APP_IMAGE', {
        src: img.src,
        uuid: response.data.photo_uuid,
        isPrimary: false,
      });
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('banner/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t(
            this.formType === 'ADD' ? 'agentBanner.add.successMsg' : 'agentBanner.edit.successMsg',
          ),
          'success',
        );
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
